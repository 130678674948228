import { compose, lifecycle } from 'recompose';

import { parseURLSearchParams } from 'shared-components/src/utils/string';
import Register from './Register';
import withHandlers from './enhancers/withHandlers';

export default compose(
  lifecycle({
    componentDidMount() {
      const { updateLoading, updateUser, updateError, location } = this.props;

      const params = parseURLSearchParams(location.search);
      const { pwt } = params;

      if (pwt) {
        return fetch(`/api/v1/register_user?token=${pwt}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
          .then(res => res.json())
          .then(body => {
            body.error
              ? updateError(body.error.message)
              : updateUser(body.data.user);
            updateLoading(false);
          })
          .catch(err => {
            updateError('Unable to contact Pungle Platform');
            updateLoading(false);
          });
      }
      updateLoading(false);
      updateError('Unable to validate email link');
    },
  }),
  withHandlers
)(Register);
